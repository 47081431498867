<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Wie zijn wij?</b-card-title>
                <b-img align="right" class="w-50 cardimage"  v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Karikaturen.jpg'" fluid></b-img>
                <b-card-text>Ridder en de Prins staat voor maatwerk en entertaint met een eigen stijl. Bij ons staan de genodigden centraal. Het hele jaar rond zijn wij, Anouk, Kristel, Yorick en Ludo, bezig om ons hele team te ondersteunen. Samen met dat team maken we die eigen Ridder en de Prinsstijl en zo kan alles maatwerk zijn. Zo hebben onze acteurs een grote rol in het maken en verfijnen van ons repertoire. Hoe kunnen we meer reactie van de kinderen krijgen en wat viel in de smaak bij ouders? Ook onze muzikanten zijn met die vraag bezig want onze muziek moet passen in het geheel. Voor onze kostuummakers zit er veel voldoening in het creëren van een uniek pak, speciaal voor één acteur. Wat zijn de specifieke wensen, wat werkt voor hen goed? Onze decorbouwers ondersteunen dan weer de acteurs van onze theatervoorstelling. Onze taak is alles te begeleiden en te ontzorgen om het plezier voor iedereen zo groot mogelijk te maken. Als ons team plezier heeft dan brengen ze dat plezier ook over naar onze bezoekers, gasten of opdrachtgevers. 
                </b-card-text>
            </b-card>
            <b-card>
                <b-card-title>Anouk, Kristel, Yorick en Ludo</b-card-title>
                <b-img align="left" class="w-50 cardimage"  v-bind:src="$imagestore2+'images/artikelen/anouk-kristel-yorick-ludo.png'" fluid alt="Fluid image"></b-img>
                <b-card-text>
                    Wij zijn het gezicht van Ridder en de Prins. <br> De kracht zit onze samenwerking, door ieders talent benutten en elkaar te ondersteunen staan we sterk. <br> Waar Ludo en Anouk creatief zijn hebben Kristel en Yorick een talent voor organiseren. <br>Samen delen we een passie voor het neerzetten van grote en kleine evenementen.
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Wie'
    }
</script>

<style>

</style>